import React ,{useEffect} from 'react';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useUserContext } from '../../context/UserStore';
import { getMyGoals } from '../../api/user';
import {GoalAccordion } from '../Accordion/GoalAccordion';

const columns = [
  {
    field: 'tasks',

    headerName: 'Tasks',
    width: 150,
    renderCell:(item)=>{
      console.log(item)
      return (<>
        <GoalAccordion notes={item.row.notes} tasks={item.value} currentGoal={false} goalName={item.row.id}/> 
      </>)
    }
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 150,
  },
  
  {
    field: 'id',
    headerName: 'Created On',
    width: 150,
  },
];

const rows = [
  //goals
];

export default function GoalsDataGrid() {
  const { state: userState, action: userActions } = useUserContext();
  useEffect(()=>{
    userActions.getMyGoalsAsyncAction()
  },[])
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      {/* <DataGrid
      rowHeight={500}
      style={{backgroundColor:'white',margin:10}}
        rows={[...userState?.myGoals || []].reverse()}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        disableRowSelectionOnClick
      /> */}
      {[...userState?.myGoals || []].reverse().map((item)=>{
        console.log(item)
        return (
          <>
          <GoalAccordion notes={item.notes} tasks={item.tasks} currentGoal={false} goalName={item.id}/>
          </>
        )
      })}
    </Box>
  );
}
