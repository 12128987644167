import * as React from 'react';

import { doRule } from '../../api/rule';
import { useUserContext } from '../../context/UserStore';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  position: 'absolute',
  p: 4,
};

export const Do = (props) => {
  const { id, overwritePoints } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [description, setDescription] = React.useState('');
  const [points, setPoints] = React.useState(overwritePoints);
  const { state: userState, action: userActions } = useUserContext();
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleChangePoints = (event) => {
    setPoints(event.target.value);
  };
  const handleSubmit = async () => {
    await doRule(id, description, points);
    userActions.getUserEventsAsync();
    userActions.getUserDataAsyncAction();
    handleClose();
  };
  return (
    <>
      <div>
        <Button
          style={{ border: '20px ridge black' }}
          onClick={async () => {
            handleOpen();
          }}
        >
          Do
        </Button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Description
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Enter a description for the rule you're doing.
            </Typography>
            <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              onChange={handleChangeDescription}
              value={description}
            />
            <TextField
              id="outlined-basic"
              label="Points"
              variant="outlined"
              onChange={handleChangePoints}
              value={points}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};
