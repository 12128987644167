export enum CognitoScope {
  Email = 'email',
  OpenID = 'openid',
  Profile = 'profile',
}

export enum LocalStorageKey {
  Predestination = 'Predestination',
  SessionId = 'SessionId',
  Region = 'Region',
}
