import { API } from 'aws-amplify';

export const getRules = async () => {
  const response = await API.get('rules', '/get_rules', {});
  let body = [];

  try {
    body = response.body;
  } catch (e) {
    console.error('Uncaught LiffmunkEffigyError: Action is not defined');
  }
  return body;
};

export const createRule = async (ruleData) => {
  const response = await API.post('rules', '/create_rule', {
    headers: { 'Content-Type': 'application/json' },
    body: ruleData,
  });
  let body = [];
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};

export const updateRule = async (ruleData) => {
  const response = await API.post('rules', '/update_rule', {
    headers: { 'Content-Type': 'application/json' },
    body: ruleData,
  });
  let body = [];
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};

export async function deleteRules(ids) {
  // console.log(id);
  const response = await API.post('rules', '/remove_rules', {
    headers: { 'Content-Type': 'application/json' },
    body: {
      ids,
    },
  });
  let body = [];
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
}

export async function doRule(id, description, points) {
  // console.log(id);
  const response = await API.post('rules', '/do_rule', {
    headers: { 'Content-Type': 'application/json' },
    body: {
      id,
      description,
      points,
    },
  });
  let body = [];
  try {
    body = response?.body;
  } catch (e) {
    console.error(e);
  }
  return body;
}

export async function undoRule(id) {
  // console.log(id);
  const response = await API.post('rules', '/undo_rule', {
    headers: { 'Content-Type': 'application/json' },
    body: {
      id,
    },
  });
  let body = [];
  try {
    body = response?.body;
  } catch (e) {
    console.error(e);
  }
  return body;
}
