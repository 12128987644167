import React, { useEffect, useState } from 'react';

import { doRule } from '../../api/rule';
import OtherEventsDataGrid from '../../component/DataGrid/OtherEventsDataGrid';
import UserActionsDataGrid from '../../component/DataGrid/UserActionsDataGrid';
import { SideMenu } from '../../component/Sidemenu/SideMenu';
import { CreateRuleModal } from '../../component/modal/CreateRuleModal';
import { Do } from '../../component/modal/DoModal';
import { useRuleContext } from '../../context/RuleStore';
import { useUserContext } from '../../context/UserStore';
import './HomeView.css';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Button, Card, CardContent, CardHeader, InputAdornment, TextField, Typography } from '@mui/material';
import { ConfirmDeleteRuleModal, EditRuleModal, RuleDescriptionModal, SetPointsModal } from 'src/component/modal';
import { SetTargetPoints } from '../../component/modal/UpdateTargetPoints';

export const HomeView = () => {
  const { state: userState, action: userActions } = useUserContext();
  const { state: ruleState, action: ruleActions } = useRuleContext();
  //useStates
  const [search, setSearch] = useState('');
  const [rules, setRules] = useState(ruleState?.rules);
  //components
  const Bull = () => <div id="bull" />;

  //useEffects
  useEffect(() => {
    userActions.getUserDataAsyncAction();
  }, []);

  useEffect(() => {
    ruleActions.getRulesAsyncAction();
  }, []);
  useEffect(() => {
    if (search.trim() === '') {
      setRules(ruleState.rules);
      return undefined;
    }
    setRules(ruleState?.rules?.filter((item) => item.name.toLowerCase().includes(search.trim().toLowerCase())));
  }, [search, ruleState.rules]);
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  return (
    <>
      {/* <img src="/image/hugs-mobile.svg"/>
      <img src='/image/favicon2.png'/> */}
      <button
        style={{ width: '90px', height: '45px' }}
        className="button btn btn-white slide"
        onClick={ruleActions.showEditRuleModalAction}
      >
        Create Rule
      </button>
      
      <br />

      <div style={{maxWidth:'100px',margin:'auto',display:'flex',flexDirection:'row'} }>
      
      &nbsp; <pre className="text">Username:</pre>&nbsp;<pre className='space text'>{userState.username}</pre> <br/>
      </div>
      
      <div style={{maxWidth:'100px',margin:'auto',display:'flex',flexDirection:'row'} }>
      &nbsp; <pre className="text">Target Points:</pre>&nbsp;<pre className='space text'>{userState.targetPoints}</pre> 
      </div>
      <div style={{maxWidth:'100px',margin:'auto',display:'flex',flexDirection:'row'} }>
      &nbsp; <pre className="text">Points:</pre>{' '}
 
      <div style={{margin:'auto', border: '20px ridge azure', borderRadius: '40%', maxWidth: '60px' ,padding:'10px'}} className="space text">
        {' '}
        <div className='text'>{userState.points}</div>
        </div>
      </div>
      <SetPointsModal c={userActions.getUserDataAsync} />
      <br/>
      <SetTargetPoints  />
      <Card style={{margin:'auto',display:'flex',flexWrap:'wrap'}} sx={{  margin: 2 }}>
        <CardContent>
          <div style={{display:'flex',alignItems:'center'}}>
          Rules
          </div>
<div style={{margin:'auto',display:'flex',justifyContent:'center',width: '100%'}}>
          <TextField
          label="Search"
          
            id="standard-basic"
            variant="standard"
            onChange={handleChange}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
          />
</div>
          <Typography variant="h5" component="div"></Typography>
          <div style={{margin:'auto',display:'flex',flexWrap:'wrap',width:'2000px'}} sx={{  margin: 2 }}>
          {rules?.map((rule) => {
            return (
              <div key={rule.id} style={{ display: 'flex', alignItems: 'center' }}>
                {/* <div sx={{ boxShadow: 5 }} style={{display: 'flex', padding:'10px',alignItems:'center',margin:'10px',borderRadius:'50px'}}> */}
                <Box sx={{ boxShadow: 5 }} style={{display: 'flex', padding:'10px',alignItems:'center',margin:'10px',borderRadius:'50px'}}>
             
                <Box key={`${rule.name}`} sx={{ boxShadow: 5 }} style={{display: 'flex', padding:'10px',alignItems:'center',margin:'10px',borderRadius:'50px'}}>

                  {rule.name}:&nbsp;&nbsp;{rule.points}
                </Box>
                &nbsp;&nbsp;&nbsp;
                <Button
                  sx={{ border: '20px ridge black' }}
                  onClick={() => {
                    ruleActions.selectRule(rule);
                    ruleActions.showRuleDescriptionModalAction();
                  }}
                >
                  Description
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Do id={rule.id} overwritePoints={rule.points} />
                <ConfirmDeleteRuleModal id={rule.id} name={rule.name} />
                &nbsp;&nbsp;&nbsp;
                <EditRuleModal name={rule.name} points={rule.points} description={rule.description} id={rule.id} />
                </Box>
              </div>
            );
          })}</div>
          {/* <pre>{JSON.stringify(isCheckedRules, null, 4)}</pre> */}
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ minWidth: 275, margin: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            History
          </Typography>
          <Typography variant="h5" component="div"></Typography>
        </CardContent>
        {/* <CardActions></CardActions> */}
      </Card>
      <UserActionsDataGrid />
      <OtherEventsDataGrid />
      <CreateRuleModal />
      <RuleDescriptionModal />
    </>
  );
};
// [key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:
//   [key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:
//     [key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:
//       [key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:[key:string]]]
//     ]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]