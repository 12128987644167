import React, { createContext, useReducer } from 'react';

// export const globalInitialState = {
//     username: 'n/a',
//     points: 0
// };

// export const Context = createContext({
//     state: initialState,
//     dispatch: () => null
// })

// export const Store = ({children}) => {
//     const [state, dispatch ] = useReducer(reducer, initialState)

//     return (
//         <Context.Provider value={{state, dispatch }}>
//             {children}
//         </Context.Provider>
//     )
// }

export const useStore = ({ reducer, initialState }) =>
  new Store({
    initialState,
    reducer,
  });

export class Store {
  initialState;
  state;
  context;
  store;
  reducer;
  constructor({ initialState, reducer }) {
    this.initialState = initialState;
    this.context = createContext({
      state: initialState,
      dispatch: () => null,
    });
    this.reducer = reducer;

    this.store = ({ children }) => {
      const [state, dispatch] = useReducer(reducer, initialState);
      this.state = state;
      return <this.context.Provider value={{ state, dispatch }}>{children}</this.context.Provider>;
    };
  }
}
