import React, { useState } from 'react';

import { useUserContext } from '../../context/UserStore';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const CreateGoalModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [notes, setNotes] = useState('');
  const [tasks, setTasks] = useState('');
  const { state: userState, action: userActions } = useUserContext();
  const handleChangeNotes = (event) => {
    setNotes(event.target.value);
  };
  const handleChangeTasks = (event) => {
    setTasks(event.target.value);
  };
  const handleSubmit = () => {
    let tasksArray = tasks.split('\n');
    tasksArray = tasksArray.map((item) => {
      return { name: item, done: false };
    });
    userActions.createGoalAsyncAction(notes, tasksArray);
  };
  return (
    <div>
      <Button onClick={handleOpen} variant="contained" style={{borderRadius:'50px',backgroundColor:'red', border:'20px ridge green'}} className='button'>
        Create Goal
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Goal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Create a new goal.
          </Typography>
          <TextField id="outlined-basic" label="Notes" variant="outlined" value={notes} onChange={handleChangeNotes} />
          <TextField
            id="outlined-basic"
            label="Tasks"
            variant="outlined"
            value={tasks}
            onChange={handleChangeTasks}
            multiline
          />
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
