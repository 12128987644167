import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../../context/GlobalStore';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export const SideMenu = () => {
  const { state: globalState, action: globalActions } = useGlobalContext();
  const navigate = useNavigate();
  const handleMenuClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <Drawer anchor="left" open={globalState.isSideMenuOpen} onClose={globalActions.toggleSideMenu}>
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            <ListItem key="Home" disablePadding>
              <ListItemButton
                onClick={() => {
                  handleMenuClick('/');
                }}
              >
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Goals" disablePadding>
              <ListItemButton
                onClick={() => {
                  handleMenuClick('/goals');
                }}
              >
                <ListItemText primary="Goals" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};
