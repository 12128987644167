import { getEvents } from '../api/event';
import { getRules, undoRule } from '../api/rule';
import { Action } from './Action';

export class RuleAction extends Action {
  setRules = (rules) => {
    this.dispatch({
      rules,
      type: 'SimpleUpdate',
    });
  };

  showDeleteModalAction = () => {
    this.dispatch({
      isDeleteModalOpen: true,
      type: 'SimpleUpdate',
    });
  };

  showEditRuleModalAction = () => {
    this.dispatch({
      isEditRuleModalOpen: true,
      type: 'SimpleUpdate',
    });
  };

  hideEditRuleModalAction = () => {
    this.dispatch({
      isEditRuleModalOpen: false,
      type: 'SimpleUpdate',
    });
  };

  toggleEditRuleModalAction = () => {
    this.dispatch({
      isEditRuleModalOpen: !this.state.isEditRuleModalOpen,
      type: 'SimpleUpdate',
    });
  };

  showRuleDescriptionModalAction = () => {
    this.dispatch({
      isRuleDescriptionModalOpen: true,
      type: 'SimpleUpdate',
    });
  };

  hideRuleDescriptionModalAction = () => {
    this.dispatch({
      isRuleDescriptionModalOpen: false,
      type: 'SimpleUpdate',
    });
  };

  toggleRuleDescriptionModalAction = () => {
    this.dispatch({
      isRuleDescriptionModalOpen: !this.state.isRuleDescriptionModalOpen,
      type: 'SimpleUpdate',
    });
  };

  getRulesAsyncAction = async () => {
    const newRules = await getRules();
    this.setRules(newRules);
  };

  selectRule = (rule) => {
    this.dispatch({
      selectedRule: rule,
      type: 'SimpleUpdate',
    });
  };
}
