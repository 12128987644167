import React from 'react';

import { useGlobalContext } from '../../context/GlobalStore';
import { useUserContext } from '../../context/UserStore';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';
import ProfileOptionsMenu from '../Menu/ProfileOptionsMenu';

export const AppHeader = (props) => {
  const { state: globalState, action: globalActions } = useGlobalContext();
  const { state: userState } = useUserContext();
  const { title } = props;
  return (
    
    <AppBar position="static">
      <Toolbar>
        <div onClick={globalActions.toggleSideMenu}>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
        </div>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        
        <ProfileOptionsMenu/>
      </Toolbar>
    </AppBar>
  );
};
