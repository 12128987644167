import { Action } from './Action';

export class GlobalAction extends Action {
  setPoints = (points) => {
    this.dispatch({
      points,
      type: 'SimpleUpdate',
    });
  };
  toggleSideMenu = () => {
    console.log(this.state);
    this.dispatch({
      isSideMenuOpen: !this.state.isSideMenuOpen,
      type: 'SimpleUpdate',
    });
  };
}
