import * as React from 'react';

import { deleteRules } from '../../api/rule';
import { useRuleContext } from '../../context/RuleStore';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ConfirmDeleteRuleModal = (props) => {
  const { id, name, getRulesAsync } = props;
  const { state: ruleState, action: ruleActions } = useRuleContext();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    await deleteRules([id]);
    ruleActions.getRulesAsyncAction();
    handleClose();
  };
  return (
    <>
      <IconButton aria-label="delete" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Comfirm Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete the rule '{name}'?
          </Typography>
          <Button onClick={handleClose} style={{ border: '20px ridge red' }}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button onClick={handleDelete} style={{ border: '20px ridge green' }}>
            Delete {name}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
