import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import { AppHeader } from './component/Header/AppHeader';
import { SideMenu } from './component/Sidemenu/SideMenu';
import { RuleStore } from './context/RuleStore';
import { UserStore } from './context/UserStore';
import { GoalsView } from './view/GoalsView/GoalsView';
import { HomeView } from './view/HomeView/HomeView';
import { Box } from '@mui/material';
import AppHeaderv2 from './component/Menu/ProfileOptionsMenu';

function App() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppHeader title="Trust Points" />
      </Box>
      <div className='content'>
      <Routes>
        <Route
          path="/goals"
          element={
            <RuleStore.store>
              <GoalsView />
            </RuleStore.store>
          }
        />
        <Route
          path="/"
          element={
            <>
              <RuleStore.store>
                <HomeView />
              </RuleStore.store>
            </>
          }
        />
        
      </Routes>
      </div> 
      <SideMenu />
    </>
  );
}
export default App;
