import React, { useEffect } from 'react';


import { CreateGoalModal } from '../../component/modal/CreateGoalModal';
import { Do } from '../../component/modal/DoModal';
import { PlanRuleModal } from '../../component/modal/PlanRuleModal';
import { useRuleContext } from '../../context/RuleStore';
import { useUserContext } from '../../context/UserStore';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import GoalsDataGrid from '../../component/DataGrid/GoalsDataGrid';
import { GoalAccordion } from '../../component/Accordion/GoalAccordion';

export const GoalsView = () => {
  const { state: ruleState, action: ruleActions } = useRuleContext();
  const { state: userState, action: userActions } = useUserContext();
  useEffect(() => {
    userActions.getGoalsAsyncAction();
  }, []);
  useEffect(() => {
    ruleActions.getRulesAsyncAction();
  }, []);
  console.log(ruleState);
  return (
    <>
      <GoalAccordion notes={userState.currentGoal.notes} tasks={userState.currentGoal.tasks} currentGoal={true} goalName={undefined}/>
      <CreateGoalModal />
      <Card>
        <div style={{margin:'10px'}}>
        Goal History
        </div>
        <CardContent>
      <GoalsDataGrid />
      </CardContent>
      </Card>
      {/* {ruleState?.rules?.filter((item)=>{return item.points}).map((rule) => {
    return (
              <div key={rule.id} style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span key={`${rule.name}`}>
                  {rule.name}:&nbsp;&nbsp;{rule.points}
                </span>
                &nbsp;&nbsp;&nbsp;
                <PlanRuleModal/>
              </div>
            );
          })} */}
    </>
  );
};
