import React, { useEffect, useState } from 'react';

import { getEvents } from '../../api/event';
import { getUser } from '../../api/user';
import { useUserContext } from '../../context/UserStore';
import { ConfirmDeleteHistoryItem } from '../modal/ComfirmDeleteHistoryItemModal';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { Button, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
export default function UserActionsDataGrid() {
  const { state: userState, action: userActions } = useUserContext();
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    getUserEvents();
  }, []);
  const getUserEvents = async () => {
    const events = await userActions.getUserEventsAsync();
  };
  const handleUndo = (id) => {
    userActions.undoRuleAsync(id);
  };
  const columns = [
    { field: 'rule', headerName: 'Rule', renderCell: (item) => item.row.change.rule?.name || 'None', width: 230 },
    {
      field: 'description',
      headerName: 'Description',
      renderCell: (item) => item.row.change?.change?.description || 'None',
      width: 175,
    },
    {
      field: 'points',
      headerName: 'Points',
      renderCell: (item) => JSON.stringify(item.row.change?.change?.points  ) || 'None',
      width: 100,
    },
    { field: 'type', headerName: 'Type', width: 130 },
    { field: 'created_by', headerName: 'Created By' },
    {
      field: 'created_on',
      headerName: 'Created On',
      width: 300,
      valueGetter: (value) => value.replace('T', '  Time: '),
    },
    {
      field: 'id',
      headerName: 'Undo',
      renderCell: (item) => {
        return (
          <IconButton style={{ border: '5px dashed red' }} aria-label="undo" onClick={() => handleUndo(item.value)}>
            <UndoRoundedIcon />
          </IconButton>
        );
      },
      width: 400,
    },
  ];

  return (
    <>
      {selectedItems}

      <DataGrid
        // onRowSelectionModelChange={(rowSelectionData)=>{
        //   setSelectedItems(rowSelectionData)
        // }}
        // rowSelectionModel={selectedItems}
        style={{backgroundColor:'white',margin:10}}
        rows={(userState?.events || []).filter((item) => item.type === 'do_rule')}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [{ field: 'created_on', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, (userState?.events || []).length]}
        checkboxSelection
      />
      {/* <ConfirmDeleteHistoryItem ids={selectedItems}/> */}
    </>
  );
}
