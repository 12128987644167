import React, { useEffect, useState } from 'react';

import { useRuleContext } from '../../context/RuleStore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '20px ridge red',
  boxShadow: 24,
  width: 400,
  p: 4,
  borderRadius: 10,
};
export const RuleDescriptionModal = (props) => {
  const { state: ruleState, action: ruleActions } = useRuleContext();

  // State
  const [style, setStyle] = React.useState(defaultStyle);
  const [borderStyle, setBorderStyle] = useState({ border: '20px ridge red', borderRadius: 20 });

  useEffect(() => {
    if (!ruleState.isRuleDescriptionModalOpen) {
      let colorAsNumber = Math.ceil(Math.random() * 8);
      let color;
      switch (colorAsNumber) {
        case 1:
          color = 'red';
          break;
        case 2:
          color = 'orange';
          break;
        case 3:
          color = 'yellow';
          break;
        case 4:
          color = 'green';
          break;
        case 5:
          color = 'blue';
          break;
        case 6:
          color = 'indigo';
          break;
        case 7:
          color = 'purple';
          break;
        case 8:
        default:
          color = 'pink';
          break;
      }
      const border = '20px ridge ' + color;
      const newStyle = { ...style, border };
      setBorderStyle({ border, borderRadius: 20 });
      setStyle(newStyle);
    }
  }, [ruleState?.isRuleDescriptionModalOpen]);

  return (
    <>
      <Modal
        open={!!ruleState?.isRuleDescriptionModalOpen}
        onClose={ruleActions.hideRuleDescriptionModalAction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {ruleState?.selectedRule?.name}'s Description
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {ruleState?.selectedRule?.description || 'This rule has no description.'}
            <br />
            <br />
            <br />
            Rule made by {ruleState?.selectedRule?.createdBy}
          </Typography>
          <Button style={borderStyle} onClick={ruleActions.hideRuleDescriptionModalAction}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};
