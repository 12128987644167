import React, { useState } from 'react';

import { getRules, updateRule } from '../../api/rule';
import { useRuleContext } from '../../context/RuleStore';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '20px ridge #000',
  boxShadow: 24,
  borderRadius: '10%',
  p: 4,
};

export const EditRuleModal = (props) => {
  const { name, points, description, id } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [changeName, setChangeName] = useState(name);
  const [changePoints, setChangePoints] = useState(points);
  const [changeDescription, setChangeDescription] = useState(description);
  const { state: ruleState, action: ruleActions } = useRuleContext();
  const handleChangeName = (event) => {
    setChangeName(event.target.value);
  };
  const handleChangePoints = (event) => {
    setChangePoints(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setChangeDescription(event.target.value);
  };
  const handleSubmit = async () => {
    await updateRule({ id, name: changeName, points: changePoints, description: changeDescription });
    handleClose();
    ruleActions.getRulesAsyncAction();
  };
  return (
    <div>
            <IconButton aria-label="edit" onClick={handleOpen}>
      <EditIcon  style={{display:'flex'}} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Rule
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Edit '{name}'
          </Typography>
          <TextField
            id="outlined-required"
            onChange={handleChangeName}
            label="Name"
            variant="outlined"
            value={changeName}
          />
          <TextField
            id="outlined-required"
            onChange={handleChangePoints}
            label="Points"
            variant="outlined"
            value={changePoints}
          />
          <TextField
            id="outlined"
            onChange={handleChangeDescription}
            label="Description"
            variant="outlined"
            value={changeDescription}
          />
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
