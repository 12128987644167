import { GlobalAction } from '../action/GlobalAction';
import { ContextBase } from './construct/ContextBase';
import { useStore } from './construct/useStore';
import { FALSE } from 'sass';

const initialState = {
  username: 'n/a',
  points: 0,
  isToggleSetPointModalOpen: false,
  isSideMenuOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SimpleUpdate':
      let { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    default:
      return state;
  }
};

export const GlobalStore = useStore({ initialState, reducer });
export const useGlobalContext = () => new ContextBase(GlobalStore, GlobalAction);
