import React, { useEffect, useState } from 'react';

import { useUserContext } from '../../context/UserStore';
import { BlurOnRounded, Difference } from '@mui/icons-material';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import { Box, Chip } from '@mui/material';
import UpdateGoalsModal from '../modal/UpdateTasks';
import UpdateNotesModal from '../modal/UpdateNotes';
import AddTasksModal from '../modal/AddTasks';
import RemoveTasksModal from '../modal/RemoveTasks';
import moment from 'moment/moment';

export const GoalAccordion = (props) => {
  const {notes,tasks,currentGoal,goalName} = props
  // // Function to calculate the start date of a specific week in a specific year
  // function getStartDateOfWeek(year, week) {
  //   // Create a new date object for January 1st of the given year
  //   const januaryFirst = new Date(year, 0, 1);

  //   // Calculate the day of the week for January 1st (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
  //   const dayOfWeek = januaryFirst.getDay();

  //   // Calculate the offset to the first day of the first week
  //   const dayOffset = dayOfWeek <= 4 ? dayOfWeek : dayOfWeek - 7;

  //   // Calculate the start date of the target week
  //   const startDateOfWeek = new Date(year, 0, 1 + dayOffset + (week - 1) * 7);

  //   return startDateOfWeek;
  // }

  // // Function to calculate the remaining days until the start of a specific week
  // function getDaysUntilWeek(year, week) {
  //   // Get the current date
  //   const currentDate = new Date();

  //   // Get the start date of the target week
  //   const targetDate = getStartDateOfWeek(year, week);
  //   console.log(targetDate)

  //   // Calculate the difference in time (milliseconds) between the two dates
  //   const timeDifference = targetDate - currentDate;

  //   // Convert the time difference from milliseconds to days
  //   // const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  //   const daysDifference = Math.ceil(timeDifference / 86400000);

  //   return daysDifference;                                                                                                                                                                                                                                
  // }
  const { state: userState, action: userActions } = useUserContext();
  const difference = moment().endOf('week').fromNow();
  const handleCompleteGoal = (item, index) => {
    let newTask = [...userState.currentGoal?.tasks]
    newTask[index] = {...item,done:!item.done}

    userActions.createGoalAsyncAction(userState.currentGoal.notes,newTask)
  };
  return (currentGoal?
    <div>
      <Accordion defaultExpanded style={{margin:'auto'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
         Current Goal
        </AccordionSummary>
        <AccordionDetails>
          Goal for week {userState.currentGoal.week_number}
          <br />
          Goal ends {}
          <br />
          Notes:{notes || 'None'}
           <UpdateNotesModal/>
          <br />
          Task(s):
          <br/><br/>
          <div style={{display:'flex',flexDirection:'column'}} >
          {tasks?.map((item, index) => {
            return (
              <div style={{display:'flex'}}>
              <Box sx={{ boxShadow: 3 }} style={{display: 'flex', padding:'10px',alignItems:'center',margin:'10px',borderRadius:'50px'}}>

                {item.name}
                {item.optional?'':'*'}
                {item.optional? <>&nbsp; &nbsp;<Chip label='optional' color="success" size="small" /></> : <>&nbsp; &nbsp;<Chip  label='required' color="error" size="small"/></>}
                {item.done ? <>&nbsp; &nbsp;<Chip label='done' color="success" size="small" onClick={() => handleCompleteGoal(item, index)}/></> : <>&nbsp; &nbsp;<Chip  label='not done' color="error" size="small" onClick={() => handleCompleteGoal(item, index)} />
                <UpdateGoalsModal item={item} index={index}/><RemoveTasksModal index={index}/>
                </>

}
</Box>
              </div>
            );
          })}
          </div>
          
          <AddTasksModal/>
        </AccordionDetails>
      </Accordion>
    </div>
  :    <div>
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
     {goalName}
    </AccordionSummary>
    <AccordionDetails>

    Goal for week {goalName.split('-')[1]}
      <br />
      Notes:{notes || 'None'}
      <br />
      Task(s):
      <br/><br/>
      <div style={{display:'flex',flexDirection:'column'}} >
      {tasks?.map((item, index) => {
        return (
          <div style={{display:'flex'}}>
          <Box sx={{ boxShadow: 3 }} style={{display: 'flex', padding:'10px',alignItems:'center',margin:'10px',borderRadius:'50px'}}>

            {item.name}
            {item.optional?'':'*'}
            {item.optional? <>&nbsp; &nbsp;<Chip label='optional' color="success" size="small"/></> : <>&nbsp; &nbsp;<Chip  label='required' color="error" size="small"/></>}
            {item.done ? <>&nbsp; &nbsp;<Chip label='done' color="success" size="small" /></> : <>&nbsp; &nbsp;<Chip  label='not done' color="error" size="small" />
            </>

}
</Box>
          </div>
        );
      })}
      </div>
      
    </AccordionDetails>
  </Accordion>
</div>);
};
