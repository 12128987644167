import { useContext } from 'react';

export class ContextBase {
  state;
  dispatch;
  action;
  constructor(Store, Action) {
    const { state, dispatch } = useContext(Store.context);
    this.state = state;
    this.dispatch = dispatch;
    this.action = new Action({ state, dispatch });
  }
}
