import EditRoundedIcon from '@mui/icons-material/EditRounded';
import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, TextField } from '@mui/material';
import { useUserContext } from '../../context/UserStore';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function UpdateNotesModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { state: userState, action: userActions } = useUserContext();
  const [notes, setNotes] = useState(userState.currentGoal.notes);
    const handleChangeNotes = (event)=>{
        setNotes(event.target.value)
    } 
    const handleSubmit = async () => {
        await userActions.createGoalAsyncAction(notes,userState.currentGoal.tasks)
        await userActions.getMyGoalsAsyncAction()
        handleClose()
    }
    useEffect(()=>{
      setNotes(userState.currentGoal.notes)
    },[userState.currentGoal.notes])
  return (
    <div>
      <IconButton aria-label="edit" onClick={handleOpen}>
      <EditRoundedIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Notes
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           Edit this goal's notes.
          </Typography>
          {notes}
          <TextField id="outlined-basic" label="Notes" variant="outlined" onChange={handleChangeNotes} value={notes}/>
          
          <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </Modal>
    </div>
  );
}
