import { API } from 'aws-amplify';

export const getEvents = async () => {
  const response = await API.get('rules', '/get_events', {});
  let body = [];

  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};
export const deleteEvents = async (ids) => {
  const response = await API.post('rules', '/remove_events', {
    headers: { 'Content-Type': 'application/json' },
    body: { ids },
  });
  let body = [];

  try {
    body = response?.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};
