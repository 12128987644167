import { RuleAction } from '../action/RuleAction';
import { ContextBase } from './construct/ContextBase';
import { useStore } from './construct/useStore';

const initialState = {
  username: 'n/a',
  isRuleDescriptionModalOpen: false,
  isEditRuleModalOpen: false,
  selectedRule: undefined,
  Rules: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SimpleUpdate':
      let { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    default:
      return state;
  }
};

export const RuleStore = useStore({ initialState, reducer });
export const useRuleContext = () => new ContextBase(RuleStore, RuleAction);
