import { betaCognitoConfig } from './beta';
import { CognitoScope, LocalStorageKey } from './types';
import { API, Amplify, Auth, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

export const getConfig = () => {
  switch (true) {
    default:
      return betaCognitoConfig;
  }
};

const {
  awsCognitoRegion,
  awsCognitoIdentityPoolID,
  awsUserPoolsWebClientID,
  awsUserPoolsID,
  awsCognitoDomain,
  apiConfig,
  s3Bucket,
} = getConfig();
const clientId = window.clientId || awsUserPoolsWebClientID;
const identityPoolId = window.identityPool || awsCognitoIdentityPoolID;
const userPoolId = window.userPoolId || awsUserPoolsID;
const cognitoDomain = window.awsCognitoDomain || awsCognitoDomain;

/**
 * Configure cognito for AWS amplify
 */
export const configureCognito = () => {
  const port = window.location.port !== '' ? `:${window.location.port}` : '';
  const cognitoConfig = {
    aws_cognito_identity_pool_id: identityPoolId,
    aws_project_region: awsCognitoRegion,
    aws_cognito_region: awsCognitoRegion,
    aws_user_pools_id: userPoolId,
    aws_user_pools_web_client_id: clientId,
    federationTarget: 'COGNITO_USER_POOLS',
    oauth: {
      domain: cognitoDomain,
      redirectSignIn: `${window.location.protocol}//${window.location.hostname}${port}/login.html`,
      redirectSignOut: `${window.location.protocol}//${window.location.hostname}${port}/logout.html`,
      responseType: 'code',
      scope: [CognitoScope.Email, CognitoScope.OpenID, CognitoScope.Profile],
    },
  };
  Auth.configure(cognitoConfig);
  API.configure({ endpoints: apiConfig });
  window.localStorage.setItem('cognitoConfig', JSON.stringify(cognitoConfig));
};
/**
 * Configure storage for AWS amplify
 */
export const configureStorage = () => {
  const storageConfig = {
    Auth: {
      identityPoolId,
      region: awsCognitoRegion,
      userPoolId,
      userPoolWebClientId: clientId,
    },
    Storage: {
      AWSS3: {
        bucket: s3Bucket,
        region: 'us-east-1',
      },
    },
  };
  // console.log(storageConfig);
  // console.log();
  Amplify.configure(storageConfig);
};

/**
 * Starts the authentication process
 */
export const startAuthentication = () => {
  localStorage.setItem(LocalStorageKey.Predestination, location.href);
  Auth.federatedSignIn();
};
/**
 * Logs the user out and clears local storage values
 */
export const logoutUser = () => {
  localStorage.removeItem(LocalStorageKey.SessionId);
  localStorage.removeItem(LocalStorageKey.Predestination);
  Auth.signOut();
};
/**
 * Retrieves the current authenticated user if user is logged in. Returns false if user is not logged in
 * @returns boolean | string
 */
export const getAuthenticatedUser = async () => {
  try {
    const result = await Auth.currentAuthenticatedUser();
    const sessionId = localStorage.getItem(LocalStorageKey.SessionId);
    if (!sessionId) {
      const newSessionId = uuidv4();
      localStorage.setItem(LocalStorageKey.SessionId, newSessionId);
    }
    return result;
  } catch (e) {
    localStorage.removeItem(LocalStorageKey.SessionId);
    return false;
  }
};
/**
 * For restoring context of url links (ie Email links). This restores the href stored in localStorage before the user get redirect externally for single sign on.
 */
export const redirectUserBasedOnLocalstorage = () => {
  const redirect = localStorage.getItem(LocalStorageKey.Predestination);
  localStorage.removeItem(LocalStorageKey.Predestination);
  if (redirect) {
    window.location.replace(redirect);
  }
};
