import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import {
  configureCognito,
  getAuthenticatedUser,
  redirectUserBasedOnLocalstorage,
  startAuthentication,
} from './auth/authenticator';
import { GlobalStore } from './context/GlobalStore';
import { UserStore } from './context/UserStore';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
configureCognito();
// configureStorage();

const startAuth = async () => {
  if (!(await getAuthenticatedUser())) {
    startAuthentication();
  } else {
    redirectUserBasedOnLocalstorage();
  }
};
startAuth();

root.render(
  <React.StrictMode>
    <GlobalStore.store>
      <UserStore.store>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserStore.store>
    </GlobalStore.store>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
