import { UserAction } from '../action/UserAction';
import { ContextBase } from './construct/ContextBase';
import { useStore } from './construct/useStore';

const initialState = {
  username: 'n/a',
  Users: 0,
  currentGoal: {},
  
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SimpleUpdate':
      let { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    default:
      return state;
  }
};

export const UserStore = useStore({ initialState, reducer });
export const useUserContext = () => new ContextBase(UserStore, UserAction);
