import { getEvents } from '../api/event';
import { undoRule } from '../api/rule';
import { getGoal, getMyGoals, getUser, updateGoal } from '../api/user';
import { Action } from './Action';

export class UserAction extends Action {
  setUserData = (userData) => {
    this.dispatch({
      ...userData,
      type: 'SimpleUpdate',
    });
  };

  toggleSetPointModalAction = () => {
    this.dispatch({
      isSetPointModalOpen: !this.state.isSetPointModalOpen,
      type: 'SimpleUpdate',
    });
  };

  getUserDataAsyncAction = async () => {
    const userData = await getUser();
    this.setUserData({
      points: userData.points,
      targetPoints: userData.target_points,
      username: userData.username,
    });
  };

  getUserEventsAsync = async () => {
    const events = await getEvents();
    this.dispatch({
      events,
      type: 'SimpleUpdate',
    });
  };

  undoRuleAsync = async (id) => {
    await undoRule(id);
    this.getUserDataAsyncAction();
    this.getUserEventsAsync();
  };
  getGoalsAsyncAction = async () => {
    const goalData = await getGoal();
    this.setUserData({
      currentGoal: goalData,
    });
  };
  createGoalAsyncAction = async (notes, tasks) => {
    this.setUserData({currentGoal:{...this.state.currentGoal,notes,tasks}})
    await updateGoal({ notes, tasks });

  };
  getMyGoalsAsyncAction = async () => {
    const goalData = await getMyGoals();
    this.setUserData({
      myGoals: goalData,
    });
  };
}
