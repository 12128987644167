import React, { useEffect, useState } from 'react';

import { updatePoints } from '../../api/user';
import { useGlobalContext } from '../../context/GlobalStore';
import { useUserContext } from '../../context/UserStore';
import { Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import './modal.css'
export const SetPointsModal = (props) => {
  const { state: globalState, action: globalActions } = useGlobalContext();
  const { state: userState, action: userActions } = useUserContext();
  const [points, setPoints] = useState(userState.points);
  const handleOpen = () => userActions.toggleSetPointModalAction();
  const handleClose = () => userActions.toggleSetPointModalAction();

  const [error, setError] = React.useState(false);

  const handleChangePoints = (event) => {
    setPoints(event.target.value);
  };

  const handleSubmit = async () => {
    const inputToNumber = Number(points);
    if (isNaN(inputToNumber)) {
      setError("Input isn't a number.");
      return false;
    }
    setError();
    await updatePoints(points);
    userActions.getUserDataAsyncAction();
    userActions.getUserEventsAsync();
    handleClose();
  };

  useEffect(() => {
    setPoints(userState.points);
  }, [userState.points]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '50px ridge red',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Button variant="contained" style={{ border: '20px ridge blue' }} className="button" onClick={handleOpen}>
        Set Points
      </Button>
      <Modal
        open={userState.isSetPointModalOpen || false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper elevation={20} style={{ width: 100, height: 50, backgroundColor: 'black' }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ padding: '6px 0 0 3px', color: 'white' }}
            >
              Set Points
            </Typography>
          </Paper>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Set the number of points.
          </Typography>
          {userState?.points}
          <br />
          <br />
          <TextField
            error={!!error}
            style={{ border: '20px ridge blue' }}
            id="filled-basic"
            label={error ? 'Error' : 'Points'}
            variant="filled"
            value={points}
            helperText={error}
            onChange={handleChangePoints}
          />
          <Button onClick={handleSubmit}>Submit</Button>
        </Box>
      </Modal>
    </div>
  );
};
