import AddCircleIcon from '@mui/icons-material/AddCircle';
import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useUserContext } from '../../context/UserStore';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddTasksModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState('');
  const [optional,setOptional] = useState();
  const { state: userState, action: userActions } = useUserContext();
  const handleChangeName = (event)=>{
    setName(event.target.value)
} 

  const handleChangeRequired = (e)=>{
   setOptional(e.target.value)
}
const handleSubmit = async ()=>{
  let newTasks = [...userState.currentGoal.tasks]
  newTasks.push({name,optional})
 await userActions.createGoalAsyncAction(userState.currentGoal.notes,newTasks)
 userActions.getMyGoalsAsyncAction()
 handleClose()
}
  return (
    <div>
      <IconButton aria-label="edit" onClick={handleOpen}>
                <AddCircleIcon/>
                </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           Add task
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Add a new task.
          </Typography>
          <TextField id="outlined-basic" label="Name" variant="outlined" onChange={handleChangeName} value={name}/>
        
          <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Required</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={optional}
    label="Required"
    onChange={handleChangeRequired}
  >
    <MenuItem value={true}>Optional</MenuItem>
    <MenuItem value={false}>Required</MenuItem>
  </Select>
</FormControl>
<Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </Modal>
    </div>
  );
}
