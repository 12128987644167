import React from 'react';

import { createRule } from '../../api/rule';
import { RuleStore, useRuleContext } from '../../context/RuleStore';
import { UserStore, useUserContext } from '../../context/UserStore';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

export const CreateRuleModal = () => {
  const { state: userState, dispatch: userDispatch, action: userActions } = useUserContext();
  const { state: ruleState, dispatch: ruleSipatch, action: ruleActions } = useRuleContext();

  return (
    <Dialog
      open={!!ruleState?.isEditRuleModalOpen}
      onClose={() => {
        ruleActions.hideEditRuleModalAction();
      }}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const { name, points, description } = formJson;
          await createRule({
            name,
            points,
            description,
          });
          ruleActions.getRulesAsyncAction();
          ruleActions.hideEditRuleModalAction();
        },
      }}
    >
      <DialogTitle>Add new rule</DialogTitle>
      <DialogContent>
        <DialogContentText>To add new rule, you must not be a noob like austin</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="points"
          name="points"
          label="Points"
          type="text"
          fullWidth
          variant="standard"
        />

        <TextField
          autoFocus
          margin="dense"
          id="description"
          name="description"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            ruleActions.hideEditRuleModalAction();
          }}
        >
          Cancel
        </Button>
        <Button type="submit">Create Rule</Button>
      </DialogActions>
    </Dialog>
  );
};
