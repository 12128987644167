export const betaCognitoConfig = {
  awsCognitoRegion: 'us-east-1',
  awsCognitoIdentityPoolID: 'us-east-1:d50d2cb4-698e-40a7-ac58-53bab76eeeb9',
  awsUserPoolsWebClientID: '6cv6uf3gqo1o5dtq4lh6ks7al5',
  awsUserPoolsID: 'us-east-1_OPPlQqVw0',
  awsCognitoDomain: 'trust-points.auth.us-east-1.amazoncognito.com',
  apiConfig: [
    {
      name: 'rules',
      endpoint: 'https://ph4bsa7efk.execute-api.us-east-1.amazonaws.com/prod',
    },
  ],
  s3Bucket: 'image-bucket-palworld-api-na-beta',
};
