import { API } from 'aws-amplify';

export const updatePoints = async (points) => {
  const response = await API.post('rules', '/update_points', {
    headers: { 'Content-Type': 'application/json' },
    body: { points },
  });
  let body;
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};

export const updateTargetPoints = async (targetPoints) => {
  const response = await API.post('rules', '/update_target_points', {
    headers: { 'Content-Type': 'application/json' },
    body: { target_points:targetPoints },
  });
  let body;
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};
export const getUser = async () => {
  const response = await API.get('rules', '/get_user_data', {});
  let body = [];
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};
export const getGoal = async () => {
  const response = await API.get('rules', '/get_goal', {});
  let body = {};
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};
export const updateGoal = async (goalData) => {
  const response = await API.post('rules', '/update_goal', {
    headers: { 'Content-Type': 'application/json' },
    body: goalData,
  });
  let body = [];
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;

};
export const getMyGoals = async () => {
  const response = await API.get('rules', '/get_my_goals', {});
  let body = {};
  try {
    body = response.body;
  } catch (e) {
    console.error(e);
  }
  return body;
};